import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import BevelScreen from '../../../../ScreenWidthContainer/viewer/skinComps/BevelScreen/BevelScreen';
import HeaderContainer from '../../HeaderContainer';

const BevelScreenHeader: React.FC<Omit<
  IHeaderContainerProps,
  'skin'
>> = props => <HeaderContainer {...props} skin={BevelScreen}></HeaderContainer>;

export default BevelScreenHeader;
